export let année = 1
export let siècle = année * 100
export let millénaire = année * 1000
export let décennie = année * 10
export let semestre = année / 2
export let mois = année / 12
export let trimestre = mois * 3
export let jour = mois / 30.4367
export let heure = jour / 24
export let minute = heure / 60
export let seconde = minute / 60
